<!--
 * @Description: 企业-认证中心
 * @Author: huacong
 * @Date: 2021-04-06 09:43:32
 * @LastEditTime: 2021-07-20 11:46:07
 * @LastEditors: huacong
-->
<template>
  <div class="contentBox">
    <vue-element-loading
      :active="loadingActive"
      :is-full-screen="true"
      color="#2674fc"
      background-color="rgba(0,0,0,.3)"
      text="正在上传，请稍后！"
    />
    <section>
      <el-tabs v-model="activeName">
        <el-tab-pane :label="editTitle" name="first">
          <div class="tabBox">
            <div class="tabTitle">
              {{ editTitle }}
            </div>
            <div class="formBox text-center">
              <el-form
                class="comForm"
                ref="comInfo"
                :model="comInfo"
                label-width="75px"
              >
                <el-form-item label="企业名称" prop="com_name">
                  <el-input v-model="comInfo.com_name"></el-input>
                </el-form-item>
                <el-form-item label="营业执照" class="licenseBox">
                  <el-upload
                    class="avatar-uploader"
                    style="display:table"
                    :action="uploadSrc"
                    :headers="headers"
                    :data="licenseData"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess2"
                    :before-upload="beforeAvatarUpload2"
                  >
                    <img
                      v-if="licenseUrl"
                      :src="licenseUrl"
                      class="avatar langer"
                    />
                    <i
                      v-else
                      class="el-icon-plus avatar-uploader-icon langer"
                    ></i>
                  </el-upload>
                  <div class="licenseMsg">
                    <p>温馨提示：</p>
                    <p>
                      1、支持jpg、png格式的图片，最大不能超过
                      3MB，最小不低于1MB；
                    </p>
                    <p>
                      2、营业执照扫描件（复印件）上的名称必须和填写的公司名称一致；
                    </p>
                    <p>3、上传清晰的营业执照更容易通过审核。</p>
                    <p>4、请不要提交虚假信息，我们对用户提交的信息严格保密。</p>
                  </div>
                </el-form-item>
                <div class="text-center" style="padding:10px;margin-top:20px">
                  <el-button
                    class="radius-25"
                    style="width: 280px;box-shadow: 0px 2px 10px 0px #8BB7FF;"
                    type="primary"
                    v-prevent-repeat-click
                    @click="postImg"
                    v-show="haveDataBtn"
                  >
                    提交
                  </el-button>
                </div>
              </el-form>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </section>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import VueElementLoading from "vue-element-loading";
export default {
  components: {
    VueElementLoading,
  },
  data() {
    return {
      activeName: "first",
      realEmailInfo: {},
      comInfo: {},
      imageUrl: "",
      licenseUrl: "",
      uploadUrl: "",
      licenseData: {},
      loadingActive: false,
      haveDataBtn: false,
      editTitle: "企业认证",
      imgToText: "",
    };
  },
  computed: {
    ...mapState(["uploadSrc", "userInfo", "Authorization"]),
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
    headers() {
      return {
        Accept: "application/json",
        token: this.Authorization,
      };
    },
  },
  methods: {
    ...mapMutations(["changecomId", "comId"]),
    getUrl() {
      this.licenseData.fi_objid = this.userInfo.uid;
      this.licenseData.fi_objcat = "com";
      this.licenseData.act = "upload";
    },
    getComInfo() {
      if (this.comId == 0) {
        this.$http.post("company/index/getcompanyinfo").then((res) => {
          if (res.data.resultCode === 1) {
            if (res.data.resultData.length < 1) {
              this.haveDataBtn = true;
              this.editTitle = "企业认证";
              return;
            }
            this.haveDataBtn = false;
            this.editTitle = "认证信息";
            this.comInfo.com_name = res.data.resultData.com_name;
            let data = res.data.resultData;
            if (data.com_license_url != "") {
              this.licenseUrl = data.com_license_url;
            }
          }
        });
      } else {
        this.$http
          .post("company/index/getcompanyinfo", { u_company: this.comId })
          .then((res) => {
            if (res.data.resultCode === 1) {
              if (res.data.resultData.length < 1) {
                this.haveDataBtn = true;
                this.editTitle = "企业认证";
                return;
              }
              this.haveDataBtn = false;
              this.editTitle = "认证信息";
              this.comInfo.com_name = res.data.resultData.com_name;
              let data = res.data.resultData;
              if (data.com_license_url != "") {
                this.licenseUrl = data.com_license_url;
              }
            }
          });
      }
    },
    realEmail() {
      this.$refs.realEmailInfo.validate((valid) => {
        if (valid) {
          this.$http
            .post("company/user/emailauth", this.realEmailInfo)
            .then((res) => {
              if (res.data.resultCode == 1) {
                this.$message.success("邮件已发送！");
              }
            });
        } else {
          return false;
        }
      });
    },
    // 营业执照
    handleAvatarSuccess2(res, file) {
      if (res.resultCode === 0) {
        this.$message.warning("图片上传失败！");
        return;
      }
      this.licenseUrl = URL.createObjectURL(file.raw);
      console.log(res);
      let imgId = res.resultData.file_id;

      this.$http
        .get(
          "index/file_upload/upfile?fi_objid=5&fi_objcat=com&act=download&fi_id=" +
            imgId
        )
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.loadingActive = false;
            this.comInfo.url = res.data.resultData.url;
            this.$http
              .post("company/index/discern", { url: this.comInfo.url })
              .then((res) => {
                if (res.data.resultCode === 1) {
                  this.imgToText = res.data.resultData.name;
                }
              });
          }
        });
    },
    beforeAvatarUpload2(file) {
      const isJP = file.type === "image/jpeg" || file.type === "image/png";
      const isLt3M = file.size / 1024 / 1024 < 3;
      const isLt1M = file.size / 1024 / 1024 > 1;
      this.loadingActive = true;
      if (!isJP) {
        this.$message.error("上传图片只能是 JPG 格式或 PNG 格式!");
        this.loadingActive = false;
      }
      if (!isLt1M || !isLt3M) {
        this.$message.error("上传图片最大不能超过 3MB，最小不低于1MB!");
        this.loadingActive = false;
      }
      return isJP && isLt1M;
    },
    postImg() {
      let comName = this.comInfo.com_name.replace("（", "");
      comName = comName.replace("）", "");
      comName = comName.replace("(", "");
      comName = comName.replace(")", "");
      if (comName != this.imgToText) {
        this.$message.warning("所填写的企业名称与营业执照上的企业名称不符！");
        return;
      }
      this.$http.post("company/index/associated", this.comInfo).then((res) => {
        if (res.data.resultCode === 1) {
          let comid = res.data.resultData.com_id;
          this.changecomId({
            comId: comid,
          });
          this.$message.success("编辑信息已保存，2秒后跳转企业首页！");
          setTimeout(() => {
            this.$router.push("/comPage/index");
          }, 1600);
        }
      });
    },
  },
  mounted() {
    this.getUrl();
    this.getComInfo();
  },
};
</script>
<style scoped>
.formBox {
  width: 500px;
  margin: 0 auto;
}
.tabTitle {
  font-size: 28px;
  color: #2573f1;
  font-weight: bold;
  text-align: center;
  margin: 30px 0;
}
.tabTitle::before {
  content: "";
  display: inline-block;
  width: 73px;
  height: 3px;
  background-image: url("../../../assets/img/lineLeft.png");
  margin-right: 24px;
  position: relative;
  top: -7px;
}
.tabTitle::after {
  content: "";
  display: inline-block;
  width: 73px;
  height: 3px;
  background-image: url("../../../assets/img/lineRight.png");
  margin-left: 24px;
  position: relative;
  top: -7px;
}
.licenseMsg {
  width: 445px;
  position: absolute;
  top: 0;
  left: 240px;
  text-align: left;
}
.licenseMsg p:first-child,
.avatarMsg p:first-child {
  color: #ff0000;
}
.licenseMsg p {
  line-height: 30px;
}
</style>
